/* Styles ---------------------------------------------------------- */
import './styles/index.css'
import 'vue-slider-component/theme/default.css'
import 'v-calendar/style.css'
import '@/ui/plugins/vue-chartjs'
import '@/shared/utils/favicon'

import { DatePicker, setupCalendar } from 'v-calendar'
import { createApp } from 'vue'
import VueGoodTablePlugin from 'vue-good-table-next'
import timeago from 'vue-timeago3'

/* Plugins --------------------------------------------------------- */
import { initSentry } from '@/ui/plugins/sentry'
import { i18n } from '@/ui/plugins/vue-i18n'
import { router } from '@/ui/plugins/vue-router'

/* Main App ------------------------------------------------------- */
import App from './App.vue'

const app = createApp(App)

initSentry(app, router)

app.use(i18n).use(timeago).use(setupCalendar, {}).use(VueGoodTablePlugin).use(router)
app.component('VDatePicker', DatePicker)
app.mount('#app')
