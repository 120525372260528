import { THEME } from '@/shared/constants/theme'

document.addEventListener('DOMContentLoaded', () => {
  const favicon = document.querySelectorAll('[rel="icon"]')[0]

  const icon = {
    controltower: '/favicon-controltower.ico',
    optimera: '/favicon-optimera.ico',
  }

  favicon.href = icon[THEME]
})
